import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'permissions.list',
  data() {
    return {}
  },
  components: {},
  created() {
    this.fetchPermissions();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      permissions: 'permissions/permissions',
      isPermissionsLoading: 'permissions/isPermissionsLoading',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchPermissions: 'permissions/PERMISSIONS_REQUEST_LIST_GET',
    }),
    ...mapMutations({}),
  },
  destroyed() {},
}
